<div id="userrequestDetail" [hidden]="!(authorized())">
    
    <ol class="breadcrumb breadcrumb-dis">
        <li><a [routerLink]="['']">{{'views.userrequest.Home' | translate}}</a></li>
        <li><a  (click)="gotoUserRequestListPage()">{{'views.userrequest.Pending users' | translate}}</a></li>
        <li class="active" >{{user?.givenName}} {{user?.sn}}</li>
        <li class="logout"><a href="./oidc/logout">{{'views.userrequest.Log out' | translate}}</a></li>
    </ol>
    
    <h1>{{user?.givenName}} {{user?.sn}}</h1>
    <!--The alerts come here -->
  
    <div *ngFor = "let alert of alerts">
        <p class="alert " [ngClass]="{'alert-danger' : alert.type=='danger','alert-info' : alert.type=='info'}">
            {{alert.msg}}
            <i  (click)="closeAlert(i)" aria-hidden="true" class="fa fa-times" style="
             float:right;
    font-size: 22px; "></i>
        </p>
    </div>
    <div class="panel panel-default">
        <div class="panel-heading">
            <div style="width: 50px; float: left">                          
                <img src="assets/images/homepage/users.png" alt="{{'views.userrequest.Userrequest management' | translate}}" width="30px"/>
            </div><span class="">{{'views.userrequest.Pending user details' | translate}}</span>
        </div>
        
        <div class="panel-body">
            <form [formGroup]="userrequestForm" (ngSubmit)="onSubmit(userrequestForm)" class="form-horizontal" role="form">
                <div class="row ">
                    <div class="col-sm-10">
                        <div class="form-group" show-errors>
                            <label for="user.userType" class="col-sm-2 control-label ">{{'views.usertype.User type' | translate}}</label>
                            <div class="col-sm-5">
                                <input type="text" class="form-control" id="user.userType" name="userType" *ngIf="user?.userType === 'external' " disabled value="External"/>
                                <input type="text" class="form-control" id="user.userType" name="userType" *ngIf="user?.userType === 'internal' " disabled value="Internal"/>
                                <input type="text" class="form-control" id="user.userType" name="userType" *ngIf="user?.userType === 'clientidp' " disabled value="Client IDP"/>
                                <input type="text" class="form-control" id="user.userType" name="userType" *ngIf="user?.userType === 'azureb2b' " disabled value="Azure B2B"/>
                            </div>
                        </div>
                        <div class="form-group" show-errors>
                            <label for="user.status" class="col-sm-2 control-label ">{{'views.user.Status' | translate}}</label>
                            <div class="col-sm-5">
                                <img style="width: 25px" *ngIf="user?.accountStatus == 'suspended'" src="assets/images/icons/bullet_red.png" alt="{{'views.user.suspended' | translate}}" title="{{'views.user.suspended' | translate}}"/>
                                <img style="width: 25px" *ngIf="user?.accountStatus == 'active'" src="assets/images/icons/bullet_green.png" alt="{{'views.user.active' | translate}}" title="{{'views.user.active' | translate}}"/>
                                <img style="width: 25px" *ngIf="user?.accountStatus == 'pending'" src="assets/images/icons/bullet_green.png" alt="{{'views.user.active' | translate}}" title="Pending"/>

                            </div>
                        </div>
                        <div class="form-group" show-errors>
                            <label for="givenName" class="col-sm-2 control-label ">{{'views.userrequest.First name' |
                                translate}}</label>
                            <div class="col-sm-7">
                                <span class="input-group">
                                    <span class="input-group-addon"><span class="glyphicon glyphicon-user"></span></span>
                                    <input [ngClass]="{'input-error': userrequestForm.controls.givenName.status=='INVALID'&& userrequestForm.controls.givenName.touched }" type="text" class="form-control" id="givenName" name="givenName"
                                     formControlName="givenName" 
        />                        </span>
                            </div>
                            <div class="col-sm-1" *ngIf="userrequestForm.controls.givenName.errors!=null">
                                <p class="error-block" *ngIf="userrequestForm.controls.givenName.errors.required== true &&userrequestForm.controls.givenName.touched">
                                    {{'views.userrequest.Required' | translate}}</p>
                                <p class="error-block" *ngIf="!(userrequestForm.controls.givenName.errors.required) && userrequestForm.controls.givenName.status == 'INVALID' &&userrequestForm.controls.givenName.touched">{{'views.service.Invalid Input'
                                    | translate}}</p>
                            </div>
                        </div>
                        
                       <!-- Last Name -->
                <div class="form-group" show-errors>
                    <label for="sn" class="col-sm-2 control-label ">{{'views.userrequest.Last name' |translate}}</label>
                    <div class="col-sm-7">
                        <span class="input-group">
                            <span class="input-group-addon"><span class="glyphicon glyphicon-user"></span></span>
                            <input type="text" class="form-control" id="sn" name="sn"
                             [ngClass]="{'input-error': userrequestForm.controls.sn.status=='INVALID'&& userrequestForm.controls.sn.touched }"  formControlName="sn" 
/>                        </span>
                    </div>
                    <div class="col-sm-1" *ngIf="userrequestForm.controls.sn.errors!=null">
                    
                        <p class="error-block" *ngIf="userrequestForm.controls.sn.errors.required== true &&userrequestForm.controls.sn.touched">
                            {{'views.userrequest.Required' | translate}}</p>
                        <p class="error-block" *ngIf="!(userrequestForm.controls.sn.errors.required) && userrequestForm.controls.sn.status == 'INVALID' &&userrequestForm.controls.sn.touched">{{'views.service.Invalid Input'
                            | translate}}</p>
                    </div>
                </div>
                         <!-- mail -->
                <div class="form-group" >
                    <label for="mail" class="col-sm-2 control-label ">{{'views.userrequest.Email' | translate}}</label>
                    <div class="col-sm-7">
                        <span class="input-group">
                            <span class="input-group-addon">@</span>
                            <input type="text" class="form-control" name="mail" id="userrequest.mail" [attr.disabled]="true" formControlName="mail" />
                        </span>
                    </div>
              
                    <!-- <div class="col-sm-1" *ngIf="userrequestForm.controls.mail.errors!=null ">
                        
                        <p class="error-block" *ngIf="userrequestForm.controls.mail.errors.required== true &&userrequestForm.controls.mail.touched">
                            {{'views.userrequest.Required' | translate}} </p>
                           
                        <p class="error-block" *ngIf="userrequestForm.controls.mail.errors.required==null && userrequestForm.controls.mail.status == 'INVALID' &&userrequestForm.controls.mail.touched">{{"views.userrequest.The mail address is invalid" | translate}}</p>
                      
                      </div>
                      <div class="col-sm-3">
                        <p class="error-block"
                       
                        *ngIf="uniqueValue">
                        {{'views.userrequest.This mail address already exists.' | translate}}
                       
                        
                    </p>
                    </div>                    -->
                </div>
            
                         <!-- Main Language -->
                <div class="form-group" >
                    <label for="mainLanguage" class="col-sm-2 control-label ">{{'views.userrequest.Main language' | translate}}</label>
                    <div class="col-sm-7">
                        <span class="input-group">
                            <span class="input-group-addon"><span class="glyphicon glyphicon-globe"></span></span>
                            <select  formControlName="mainLanguage"  placeholder="Set main language" (change)="onLangChange($event)"
                                class="ng-touched caim-select-field ng-valid ng-not-empty ng-dirty ng-valid-parse input form-control">
                                <option value="" disabled hidden></option>
                                <option label="En - English (Global)" value="EN" selected>En - English (Global)</option>
                                <option label="Nl - Dutch" value="NL">Nl - Dutch</option>
                                <option label="Fr - French (France)" value="FR">Fr - French (France)</option>
                                <option label="Es - Spanish (Spain)" value="ES">Es - Spanish (Spain)</option>
                                <option label="Es-419 - Spanish (Latin-America)" value="ES-419">Es - Spanish
                                    (Latin-America)</option>
                                <option label="Pt - Portuguese" value="PT">Pt - Portuguese </option>
                                <option label="Zh - Chinese (Simplified)" value="ZH">Zh - Chinese (Simplified)</option>
                                <option label="De - German" value="DE">De - German</option>
                                <option label="Da - Danish" value="DA">Da - Danish</option>
                                <option label="Sv - Swedish" value="SV">Sv - Swedish</option>
                                <option label="Fi - Finnish" value="FI">Fi - Finnish</option>
                                <option label="No - Norwegian" value="NO">No - Norwegian</option>
                                <option label="Pl - Polish" value="PL">Pl - Polish</option>
                                <option label="Ja - Japanese" value="JA">Ja - Japanese</option>
                                <option label="It - Italian" value="IT">It - Italian</option>
                                <option label="Fr-Ca - French (Canada)" value="FR-CA">Fr-Ca - French (Canada)</option>
                                <option label="Hu - Hungarian" value="HU">Hu - Hungarian</option>
                            </select>
                        </span>
                    </div>
                </div>
                        <!-- telephoneNumber number -->
                      <div class="form-group" show-errors *ngIf="!(user?.userType === 'internal' || user?.userType === 'clientidp' || user?.userType === 'azureb2b')">
                        <label for="user.telephoneNumber" class="col-sm-2 control-label ">{{'views.public.pwdset.Mobile'| translate}}</label>                       
                            <div class="col-sm-7">
                                <ngx-intl-tel-input 
                                [preferredCountries]="preferredCountries"
                              [enableAutoCountrySelect]="true"
                               [enablePlaceholder]="true"
                                [searchCountryFlag]="true"
                              [searchCountryField]=
                              "[SearchCountryField.Iso2, 
                              SearchCountryField.Name]" 
                              [selectFirstCountry]="false"
                              [selectedCountryISO]="CountryISO.UnitedStates"
                              [maxLength]="15"
                              [phoneValidation]="true" 
                              [separateDialCode]="true"
                              (ngModelChange)="checkNumber()"
                              name="telephoneNumber" 
                              formControlName="telephoneNumber">
</ngx-intl-tel-input>
                       
                            </div>
                        </div>
                        <div class="col-sm-7">
                            <div class="btn-group savebutton">
                                <button type="button"
                                        class="btn btn-default"
                                        (click)="onSubmit(userrequestForm)"
                                        [attr.disabled]="userrequestForm.status=='INVALID' ? '' : null ">
                                    <span class="glyphicon glyphicon-floppy-disk" style="margin-right: 10px"></span>{{'views.user.Save' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <accordion>
        <div id="orgmembership" >
            <accordion-group is-open="false" is-disabled="false">

                    <div accordion-heading  style="width: 100%;">  
                        <div style="width: 50px; float: left">                    
                        <img src="assets/images/homepage/organizations.png" alt="{{'views.user.Organization membership' | translate}}" width="30px"/>
                       </div> {{'views.user.Organization membership' | translate}} ({{organizationcount}})
                    </div>
               
                <span class="" *ngIf="organizationcount < 1">
                    {{'views.user.The user is not a member of any organization.' | translate}} <br />
                </span>
                <div *ngIf="organizationcount >= 1">
                    <table mat-table [dataSource]="dataSource" matSort #myTable>
                        <!-- Progress Column -->
                        <ng-container matColumnDef="organization">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Organization Name</th>
                          <td mat-cell *matCellDef="let row" (click)="navigateorgDetail(row)">{{row.name}} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="roledisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: roledisplayedColumns;"></tr>
                      </table>
                    </div>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [length]="organizationcount" [pageSize]="10"></mat-paginator>
   
           
            </accordion-group>
        </div>
        <div id="roleassignments" >
            <accordion-group is-open="false" is-disabled="false">

                <div accordion-heading  style="width: 100%;">  
                    <div style="width: 50px; float: left">                          
                        <img src="assets/images/homepage/link.png" alt="{{'views.userrequest.Activation link' | translate}}" width="25px"/>
                    </div>
                    {{'views.userrequest.Activation link' | translate}}
                </div>
                <div class="panel-body">
                    <b>{{'views.userrequest.Activation link sent on:' | translate}} </b>  {{suspendjson?.linkStartDate | date:'medium'}}<br/>
                        <b>{{'views.userrequest.Link expires on:' | translate}} </b>  {{suspendjson?.validity | date:'medium'}}
                    <!-- <div *ngFor="let er of user?.mailReferencesCollection">
                        <b>{{'views.userrequest.Activation link sent on:' | translate}} </b>{{er.sentOn | date:'medium'}}<br />
                        <b>{{'views.userrequest.Link expires on:' | translate}} </b>{{er.validUntil | date:'medium'}}
                    </div> -->
                    <br/>
                    <div class="btn-group">
                        <button type="button" class="btn btn-default" (click)="newLink(user)"  [attr.disabled]="newLinkButtonClicked ? '' : null">
                            <span class="glyphicon glyphicon-envelope" style="margin-right: 10px">
                            </span>
                            {{'views.userrequest.Send new activation link' | translate}}
                        </button>
                    </div>
                </div>
                </accordion-group>
        </div>
        </accordion>

    
        <app-terms-conditions></app-terms-conditions>
</div>