<div class="login-page activate-page">
    <div class="main-container self-service-main-container" style="display: block;">
        <div class="breadcrumbs ng-scope" style="margin-top: 10px; padding-bottom: 10px;">
            <a routerLink="/home" class="link link-breadcrumbs"
                style="float: left; font-size: 14px">{{'views.selfservice.Home'|
                translate}}</a>
            <a href="oidc/logout" class="link link-breadcrumbs"
                style="float: right; font-size: 14px">{{'views.selfservice.Log out'| translate}}</a>
        </div>
        <div class="header-admin-page-form detail-edit-page-header">
            <div class="form-title-container">
                <div class="form-title title" style="margin-top: 20px">
                    {{'views.selfservice.Welcome'| translate}} {{headerName}}
                </div>

                <!--The alerts come here-->
                <!-- <alert ng-repeat="alert in alerts" type="{{alert.type}}" close="closeAlert($index)"
            style="border-radius: initial; font-size: 14px; margin-top: 10px; margin-bottom: 10px; width: 390px">
            {{alert.msg}}</alert> -->
                <!-- background-color: #cff4fc; -->
                <div *ngFor = "let alert of alerts">
                    <p class="alert " [ngClass]="{'alert-danger' : alert.type=='danger','alert-info' : alert.type=='info'}">
                        {{alert.msg}}
                        <i  (click)="closeAlert(i)" aria-hidden="true" class="fa fa-times" style="
                        float:right;
               font-size: 22px; "></i>
                    </p>
                </div>

                <div class="user-list-description">
                    {{'views.selfservice.What are you looking for today?'| translate}}
                </div>

            </div>
        </div>


        <div class="user-details-container">
            <span class="user-details-container-title">
                {{'views.selfservice.Update my details'| translate}}
            </span>
            <div  class="info-container">
                <form [formGroup]='userForm' class="form-horizontal" role="form">
                    <div class="field">
                        <label class="info-user-label">{{'views.selfservice.First name'| translate}}</label>
                        <input type="text" class="input" id="firstname" name="firstname" formControlName="firstname"
                            [required]="user.userType != 'internal'"/>
            
                        <div class="confirm-form send-call-form" style="max-width: none"
                            *ngIf="(userForm.controls.firstname.errors!=null)" >
                            <p class="error-block" *ngIf="userForm.controls.firstname.errors.required== true && userForm.controls.firstname.touched">
                                {{'views.userrequest.Required' | translate}}</p>
                            <p class="error-block" *ngIf="!(userForm.controls.firstname.errors.required) && userForm.controls.firstname.status == 'INVALID' &&userForm.controls.firstname.touched">{{'views.service.Invalid Input'
                                | translate}}</p>                        </div>
                    </div>
                    <div class="field">
                        <label class="info-user-label" >{{'views.selfservice.Last name'| translate}}</label>
                        <!-- <input type="text" class="input" id="user.lastname" name="lastname" ng-model="user.lastname"
                            ng-required="user.userType !== 'internal'" ng-disabled="user.userType === 'internal'" /> -->
                        <input type="text" class="input" id="lastname" name="lastname" formControlName="lastname"
                            [required]="user.userType != 'internal'"/>
                       
                            <div class="confirm-form send-call-form" style="max-width: none"
                            *ngIf="(userForm.controls.lastname.errors!=null)" >
                            <p class="error-block" *ngIf="userForm.controls.lastname.errors.required== true &&userForm.controls.lastname.touched">
                                {{'views.userrequest.Required' | translate}}</p>
                            <p class="error-block" *ngIf="!(userForm.controls.lastname.errors.required) && userForm.controls.lastname.status == 'INVALID' &&userForm.controls.lastname.touched">{{'views.service.Invalid Input'
                                | translate}}</p></div>

                    </div>
                    <div class="field">
                        <label class="info-user-label">{{'views.public.otpmail.Email'| translate}}</label>
                        <input type="text" ng-readonly="true" class="input" id="email" name="email"
                             formControlName="email" [disabled]="true"
                            pattern="/^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/" />
                    </div>
                    <div class="field">
                        <div class="form-controls form-container-method">
                            <label class="info-user-label">{{'views.selfservice.Main language'| translate}}</label>
                            <!-- <label *ngIf="userForm.controls.currentLocaleDisplayName.status=='VALID'"
                                class="input-form-label label-success">{{"views.public.pwdset.SelectLanguage"|
                                translate}}</label> -->
                            <!-- <label *ngIf="userForm.controls.currentLocaleDisplayName.status=='INVALID'"
                                class="input-form-label">{{"views.public.pwdset.SelectLanguage"| translate}}</label> -->
                            <!-- <div class="main-text">
                                <p>{{"views.public.pwdset.This will be the default option when you log in to your
                                    account
                                    going forward."| translate}}</p>
                            </div> -->
                            <select formControlName="currentLocaleDisplayName" required (change)="changeLanguage()"
                                class="ng-touched caim-select-field ng-valid ng-not-empty ng-dirty ng-valid-parse input">
                                <option *ngFor="let language of localesDisplayNames" [ngValue]="language.langCode | uppercase">
                                    {{language.langValue}}</option>
                            </select>
                        </div>
                    </div>
                </form>
                <!-- <button type="button"
                class="btn-form btn-black login-btn"
                ng-click="submit()"
                ng-disabled="userForm.$invalid || userForm.$pristine">
            <span class="glyphicon glyphicon-floppy-disk" style="margin-right: 10px"></span>{{'views.selfservice.Save'| translate}}
        </button> -->
            </div>
            <!-- *ngIf="user?.userType !== 'internal' && user?.userType !=='azureb2b'" -->
            <ng-container *ngIf="!(user?.userType == 'internal' || user?.userType =='azureb2b')">
                <div class="manage-credentials-container">
                    <div class="manage-credentials-title">
                        {{'views.selfservice.Change my password'| translate}}
                    </div>
                    <button class="btn-form btn-white login-btn credentials-btn" style="width: 100%;"
                        (click)="resetPassword(userForm.controls['email'].value)">
                        {{'views.selfservice.Send reset password link'| translate}}
                    </button>
                </div>
                </ng-container>
                <ng-container *ngIf="!(user?.userType == 'internal' || user?.userType =='azureb2b' || tenantValue == 'swiss')">
                    <!-- ng-hide="user.userType === 'internal' || user.userType === 'azureb2b'" -->
                <div class="manage-credentials-container">
                    <div class="manage-credentials-title">
                        {{'views.selfservice.Configure my MFA method'| translate}}
                    </div>
                    <p class="user-list-description"> {{'views.selfservice.Configure my MFA method2'| translate}}</p>
                    <div class="manage-credentials-title" style="font-size: 14px">
                        {{'views.selfservice.Set up your mobile phone'| translate}}
                    </div>
                </div>
                <div class="field">
                    <label class="info-user-label">{{'views.public.pwdset.Mobile'| translate}}</label>
                    <form [formGroup]="phoneForm" class="form-horizontal" role="form" autocomplete="off"
                        *ngIf='!validateMobilOTP && !mobilephonecallchecked'>
                        <div class="confirm-form phone-call-form"
                            style="margin-top: 15px;max-width: inherit;display: block;">
                            <!-- <input ng-model="user.mobile"
                            placeholder="{{'views.public.pwdset.Format: +11234567890' | translate}}" type="text"
                            name="mobile" class="input input-password" required autocomplete="off"
                            ng-change="phoneChanged(user.mobile)" ng-pattern="/^\+[1-9]\d{7,14}$/"> -->
                            <ngx-intl-tel-input [preferredCountries]="preferredCountries" [cssClass]="'tel-input'"
                                [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                                [searchCountryField]="[SearchCountryField.Iso2, 
                              SearchCountryField.Name]" [selectFirstCountry]="false"
                                [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="15" [phoneValidation]="true"
                                [separateDialCode]="true" name="mobile" formControlName="mobile">
                            </ngx-intl-tel-input>
                        </div>
                        <!-- [disabled]="checkMobile(phoneForm,mobile)" -->
                        <!-- [disabled]="checkMobile(phoneForm,mobile)" -->
                        <div class="confirm-form phone-call-form"
                            style="max-width: inherit;width: 100%;margin-top: 30px;">
                            <button name="mobilebtn" class="btn-form btn-white login-btn credentials-btn"
                                (click)="sendOTP('sms', phoneForm.value.mobile)"
                                [disabled]="ValidatePhoneNumber">{{"views.public.pwdset.SMS" | translate}}</button>
                            <button name="mobilebtn" class="btn-form btn-white login-btn credentials-btn"
                                (click)="sendOTP('call', phoneForm.value.mobile)"
                                [disabled]="ValidatePhoneNumber">{{"views.public.pwdset.Mobile phone" | translate}}</button>
                        </div>
                        <!-- 
                        phoneForm?.controls?.mobile?.errors?.validatePhoneNumber && !(phoneForm?.controls?.mobile?.errors?.validatePhoneNumber?.valid
                        <div class="confirm-form send-call-form"
                        ng-show="(phoneForm.mobile.$error.pattern || phoneForm.mobile.$invalid || phoneInvalid) && mobilechecked">
                        <span class="hint-text call-send-text">{{"views.public.pwdset.PhoneValidation"|
                            translate}}</span>
                    </div> -->
                    </form>
                    <form name="telephoneForm" class="form-horizontal" role="form" [formGroup]="telephoneOtpForm"
                        *ngIf="showOTPSection">
                        <div class="form-group">
                            <!-- Show the authenticator container if the OTP was not validated yet-->
                            <div class="authenticator-container" *ngIf="!validateMobilOTP && !mobilephonecallchecked">
                                <!--Step 3-->
                                <div class="authenticator-text-step">

                                    <p class="authenticator-text-step-description">
                                        {{"views.public.pwdset.Enter the one time password mobile"|
                                        translate}}</p>
                                    <div class="confirm-form">
                                        <input formControlName="otp" type="password" autocomplete="off"
                                            name="otpvalidation" class="input"
                                            placeholder="{{'views.public.pwdset.Code'| translate}}" required>
                                        <button class="confirm-btn"
                                            (click)="verifyOTP(phoneForm.value.mobile,telephoneOtpForm.value.otp)">{{"views.public.pwdset.Validate OTP"| translate}}</button>
                                    </div>
                                </div>
                            </div>
                            <!--Show if OTP was valid-->
                            <br />

                            <!-- Show if OTP was not valid -->
                            <!-- <div ng-show="twiliootpalert !== null && twiliootpalert !== '' && twiliootpalert.length > 0 && !validateOTP"
                                class="mobile-method-container">
                                <div class="confirm-form confirm-form-send">
                                    <span class="send-password-text">{{twiliootpalert}}</span>
                                </div>
                            </div> -->
                        </div>
                    </form>
                    <div *ngIf="validateMobilOTP || mobilephonecallchecked" class="mobile-method-container">
                        <div class="confirm-form confirm-form-send">
                            <span class="send-password-text">{{"views.public.pwdset.OTPValid.mobile"|
                                translate}}</span>
                        </div>
                    </div>
                </div>
                <div class="manage-credentials-title" style="font-size: 14px">
                    {{'views.selfservice.Set up your Authenticator App'| translate}}
                </div>
                <p class="user-list-description" *ngIf="PreferredMFASet !== 'authenticatorapp'">{{'views.selfservice.You did not register an Authenticator app yet.'| translate}}</p>
                <!-- <p class="user-list-description" *ngIf="PreferredMFASet === 'authenticatorapp'">{{'views.selfservice.Your Authenticator App was registered on:'| translate}} <br />{{otpapplastchangeddate}}</p> -->
                <button class="btn-form btn-white login-btn credentials-btn" (click)="registerOtp()"
                    style="width: 100%;">
                    {{'views.selfservice.Configure Authenticator app'| translate}}
                </button>
                <!-- <button class="btn-form btn-white login-btn" (click)="revokeOtp()" style="width: 100%;margin-top: 15px;"
                    *ngIf="PreferredMFASet === 'authenticatorapp'">
                    {{'views.selfservice.Delete current Authenticator app registration'| translate}}
                </button> -->
                <!-- <button class="btn-form btn-white login-btn" ng-click="revokeOtp()"
                ng-hide="otpapplastchangeddate === undefined">
                {{'views.selfservice.Delete current Authenticator app registration'| translate}}
            </button> -->
                <div class="manage-credentials-title" style="font-size: 14px; margin-top: 15px">
                    {{'views.selfservice.Select a primary MFA method'| translate}}
                </div>
                <p class="user-list-description" *ngIf="PreferredMFASet !== 'authenticatorapp' || !user.mobile">
                    {{'views.selfservice.Register your phone number'| translate}}</p>
                <!-- <form name="preferredMFAForm" class="form-horizontal" role="form">
                <label class="label-container mobile-primary-method-radio mobile-primary-method-radio-first"
                    ng-hide="otpapplastchangeddate === undefined">
                    <input type="radio" name="radio" ng-model="user.preferredMFA" value="AuthenticatorApp">
                    <span class="label-text">{{'views.selfservice.Authenticator app'| translate}}</span>
                    <span class="checkmark"></span>
                </label>
                <label class="label-container mobile-primary-method-radio" ng-if="user.mobile">
                    <input type="radio" name="radio" ng-model="user.preferredMFA" value="sms">
                    <span class="label-text">{{'views.selfservice.Mobile phone (Text/SMS)' | translate}}</span>
                    <span class="checkmark"></span>
                </label>
                <label class="label-container mobile-primary-method-radio" ng-if="user.mobile">
                    <input type="radio" name="radio" ng-model="user.preferredMFA" value="voice">
                    <span class="label-text">{{'views.public.pwdset.Mobile phone (Phone call)' | translate}}</span>
                    <span class="checkmark"></span>
                </label>
                <label class="label-container mobile-primary-method-radio"
                    ng-if="user.emailOtp && (user.mobile || otpapplastchangeddate)">
                    <input type="radio" name="radio" ng-model="user.preferredMFA" value="email">
                    <span class="label-text">{{'views.public.otpmail.Email' | translate}}</span>
                    <span class="checkmark"></span>
                </label>
            </form> -->
                <form name="preferredMFAForm" class="form-horizontal" role="form" >
                    <label class="label-container mobile-primary-method-radio mobile-primary-method-radio-first" *ngIf="authApp">
                        <input type="radio" name="radio" (click)="PreferredMFASet = 'authenticatorapp'; preferredMFA = 'authenticatorapp'"  value="authenticatorapp" [checked]="PreferredMFASet === 'authenticatorapp'">
                        <span class="label-text">{{"views.public.pwdset.Authenticator app"| translate}}</span>
                        <span class="checkmark"></span>
                    </label>
                    <label class="label-container mobile-primary-method-radio" *ngIf="user.mobile">
                        <input type="radio" name="radio" value="sms" (click)="PreferredMFASet = 'sms';preferredMFA = 'sms'" [checked]="PreferredMFASet === 'sms'">
                        <span class="label-text">{{"views.public.pwdset.Mobile phone (SMS)" | translate}}</span>
                        <span class="checkmark"></span>
                    </label>
                    <label class="label-container mobile-primary-method-radio" *ngIf="user.mobile">
                        <input type="radio" name="radio" value="voice" (click)="PreferredMFASet = 'voice';preferredMFA = 'voice'" [checked]="PreferredMFASet === 'voice'">
                        <span class="label-text">{{"views.public.pwdset.Mobile phone (Phone call)" | translate}}</span>
                        <span class="checkmark"></span>
                    </label>
                    <label class="label-container mobile-primary-method-radio" *ngIf="emailOTP">
                        <input type="radio" name="radio" value="email" (click)="PreferredMFASet = 'email';preferredMFA = 'email'" [checked]="PreferredMFASet === 'email'">
                        <span class="label-text">{{"views.public.otpmail.Email" | translate}}</span>
                        <span class="checkmark"></span>
                    </label>
                </form>
            </ng-container>
        </div>
        <button type="button" class="btn-form btn-black login-btn" (click)="submit()" style="width: 100%;margin-bottom: 30px;border: none;" [disabled]="checkDisabledSave()">
            <span class="glyphicon glyphicon-floppy-disk" style="margin-right: 10px"></span>{{'views.selfservice.Save'| translate}}
        </button>
        <app-terms-conditions></app-terms-conditions>
    </div>
</div>