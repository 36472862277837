<div id="batch" *ngIf="authorized()">

    <ol class="breadcrumb breadcrumb-dis">
        <li><a [routerLink]="['']">{{'views.user.Home' | translate}}</a></li>
        <li class="active">{{'views.user.Batch upload' | translate}}</li>
        <li class="logout"><a href="./oidc/logout">{{'views.user.Log out' | translate}}</a></li>
    </ol>


    <div class="titleicon">
      <img src="assets/images/homepage/batch.png"
            alt="{{'views.user.New user' | translate}}" /></div>
            <h1>{{'views.user.Batch upload for organization' | translate}} {{selectedOrgName}}</h1>
            <div *ngFor = "let alert of alerts">
              <p class="alert " [ngClass]="{'alert-danger' : alert.type=='danger','alert-info' : alert.type=='info'}">
                  {{alert.msg}}
                  <i  (click)="closeAlert(i)" aria-hidden="true" class="fa fa-times" style="
          font-size: 22px;
         float:right;
              "></i>
              </p>
          </div>
    <div class="panel panel-default" >
        <div class="panel-heading"></div>
        <div class="panel-body">
            <div style="float:right">
                <button type="button" class="btn btn-default btn-xs" (click)="showhelp = !showhelp" title="{{'views.user.Show help' | translate}}">
                  <span class="glyphicon glyphicon-question-sign" style="margin-right: 10px"></span>
                </button>
              </div>
              <div *ngIf="showhelp" style="overflow: auto;">
                <p><span class="">
                  <img src="assets/img/batch_upload_2024.png" width="90%" height="90%" style="display:block; margin: 0 auto"></span></p>
                <p>
                  <span class="line-spacing-1 batch-list" style="line-height: 1;" >
                    {{'views.user.The batch upload module lets you upload a CSV file containing instructions for operations on users for the current organization' | translate}}
                    ({{selectedOrgName}})
                    {{'views.user.or for a specified organization.' | translate}}
                    
                    <p class="line-spacing-1" style="margin-top: 8px;" >{{'views.user.The supported operations are: ' | translate}}</p>
                    <p style="line-height: 16px;">{{'views.user.Register users' | translate}}</p>
                    <p style="line-height: 16px;" >{{'views.user.Update users’ details' | translate}}</p>
                    <p class="line-spacing-1" >&emsp;{{'views.user.Phone number change' | translate}}</p>
                    <p class="line-spacing-1" >&emsp;{{'views.user.Language change' | translate}}</p>
                    <p class="line-spacing-1" >{{'views.user.Suspend users' | translate}}</p>
                    <p class="line-spacing-1" >{{'views.user.Activate users' | translate}}</p>
                    <p class="line-spacing-1" >{{'views.user.Delete users' | translate}}</p>
                    <p class="">{{'views.user.Application Admins can invite/update/suspend/activate/delete a user only within the CIAM organization they have access to.' | translate}}</p>
                  </span>
                </p>
                <p class="line-spacing-1"><li><span class="">{{'views.The CSV input file needs to be saved in UTF-8 format' | translate}} </span></li></p>
                <p class="line-spacing-1"><li><span class="">{{'views.user.The CSV file should contain lines of the following format:' | translate}} <b>{{'views.user.It must not contain any headers.' | translate}}</b></span></li></p> 
                <p>{{'views.user.Mandatory fields are listed in uppercase and bold.' | translate}}</p>
                  <ul class="line-spacing-1 batch-list" style="padding: 16px;" >
                    <li style="white-space: nowrap;"><strong>REGISTER</strong>;<strong>EMAIL</strong>;<strong>FIRSTNAME</strong>;<strong>LASTNAME</strong>;mobile;language;<strong>ORGTECHID</strong>;<strong>SERVICETECHID</strong>;<strong>ROLETECHID</strong>;<strong>USERTYPE</strong>;<strong>ROLEENDDATE</strong>(yyyy-mm-dd)</li>
                    <li><strong>UPDATE</strong>;<strong>EMAIL</strong>;<strong>ORGTECHID</strong>;mobile;language;</li>
                    <li><strong>SUSPEND</strong>;<strong>EMAIL</strong>;<strong>ORGTECHID</strong></li>
                    <li><strong>ACTIVATE</strong>;<strong>EMAIL</strong>;<strong>ORGTECHID</strong></li>
                    <li><strong>DELETE</strong>;<strong>EMAIL</strong>;<strong>ORGTECHID</strong></li>
                </ul>

              <p class="line-spacing-1" ><span class=""><b>{{'views.user.Note:' | translate}}</b></span></p>
              <p class="line-spacing-1" ><span class="">{{'views.user.EMAIL and TECHID are mandatory for all operations.' | translate}} </span></p>
              <p class="line-spacing-1" ><span class="" style="white-space: nowrap;">{{'views.user.ROLETECHID explanation' | translate}}</span></p>
              <p class="line-spacing-1" ><span class="">{{'views.user.Default UserType' | translate}} </span></p>
              <p class="line-spacing-1" ><span class="">{{'views.user.Requestor notification' | translate}} </span></p>
              <p class="line-spacing-1" ><span class="">{{'views.user.Email attachment results' | translate}} </span></p>
              <p class="line-spacing-1" ><span class="">{{'views.user.Examples of correct lines are:' | translate}}</span></p>
              <p class="line-spacing-1"><span class="">{{'views.The CSV input file needs to be saved in UTF-8 format' | translate}} </span></p>
                <span class="">                  
                    <ul class="line-spacing-1 batch-list " style="padding: 16px;" >
                      <li style="white-space: nowrap;"><strong>REGISTER</strong>;<strong>EMAIL</strong>;<strong>FIRSTNAME</strong>;<strong>LASTNAME</strong>;mobile;language;<strong>ORGTECHID</strong>;<strong>SERVICETECHID</strong>;<strong>ROLETECHID</strong>;<strong>USERTYPE</strong>;<strong>ROLEENDDATE</strong>(yyyy-mm-dd)</li>
                      <ul style="padding: 5px;">
                          <li style="white-space: nowrap;"><strong>REGISTER</strong>;user1@test.com;user1firstname;testlastname;+11234567890;EN;<strong>CIAMDEMOORGTECHID</strong>;<strong>CIAMDEMOSERVICETECHID</strong>;ROLETECHID,ROLETECHID;azureb2b;<strong>ROLEENDDATE</strong>(2030-12-30)</li>
                          <li><strong>REGISTER</strong>;user1@test.com;user1firstname;testlastname;+11234567890;EN;<strong>CIAMDEMOORGTECHID</strong>;<strong>CIAMDEMOSERVICETECHID</strong>;ROLETECHID;azureb2b;</li>
                          <li><strong>REGISTER</strong>;user1@test.com;user1firstname;testlastname;+11234567890;LANGUAGE;<strong>ORGTECHID</strong>;;ROLETECHID;</li>
                          <li><strong>REGISTER</strong>;user1@test.com;user1firstname;testlastname;;;;;ROLETECHID</li>
                      </ul>
                      <li><strong>UPDATE</strong>;<strong>EMAIL</strong>;<strong>ORGTECHID</strong>;mobile;language;</li>
                      <ul style="padding: 4px;">
                          <li><strong>UPDATE</strong>;user1@test.com;<strong>CIAMDEMOORGTECHID</strong>;+11234567890;;</li>
                          <li><strong>UPDATE</strong>;user1@test.com;<strong>CIAMDEMOORGTECHID</strong>;;EN;</li>
                          <li><strong>UPDATE</strong>;user1@test.com;<strong>CIAMDEMOORGTECHID</strong>;+11234567890;EN;</li>
                      </ul>
                      <li><strong>SUSPEND</strong>;<strong>EMAIL</strong>;<strong>ORGTECHID</strong></li>
                      <ul style="padding: 4px;">
                          <li><strong>SUSPEND</strong>;user1@test.com;<strong>CIAMDEMOORGTECHID</strong></li>
                      </ul>
                      <li><strong>ACTIVATE</strong>;<strong>EMAIL</strong>;<strong>ORGTECHID</strong></li>
                      <ul style="padding: 4px;">
                          <li><strong>ACTIVATE</strong>;user1@test.com;<strong>CIAMDEMOORGTECHID</strong></li>
                      </ul>
                      <li><strong>DELETE</strong>;<strong>EMAIL</strong>;<strong>ORGTECHID</strong></li>
                      <ul style="padding: 4px;">
                          <li><strong>DELETE</strong>;user1@test.com;<strong>CIAMDEMOORGTECHID</strong></li>
                      </ul>
                  </ul>                
                </span>
                <br><br>
              </div>
              <div *ngIf="selected"><p><span class="">{{'views.user.Selected file:' | translate}} {{selectedfile.name}} ({{selectedfile.size / 1000 | number:0}} kb)</span></p>
              </div>
              <div *ngIf="showprogress" class="progress" style="height: 20px">
                <div
                  class="progress-bar"
                  role="progressbar"
                  [ngStyle]="{'width': progresswidth}"
                  
                  aria-valuenow="(progressvalue / progressmax)*100 | number:0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                
                {{(progressvalue / progressmax)*100 | number:0 }}%
                </div>
              </div>  
              <div class="btn-group">
                <button type="button" class="btn btn-default" (click)="download()" title="Download file">
                        <span class="glyphicon glyphicon-save" style="margin-right: 10px"></span>
                        {{"views.user.Download batch upload template"| translate}}
                </button>
        </div>
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <div class="choose_file" >
              <button type="button"   class="btn btn-default btn-group" title="Select file">
                
                <span class="glyphicon glyphicon-paperclip" style="margin-right: 10px"></span>
                  {{'views.user.Select your CSV file' | translate}}
              </button>
              <input type="file" (change)="selectfile($event)"  accept=".csv,.CSV">  
            </div>
          </div>
          <div class="btn-group">
              <button [attr.disabled]="selected==false ? '':null" type="button" class="btn btn-default" (click)="upload()" title="Upload file">
                <span class="glyphicon glyphicon-open" style="margin-right: 10px"></span>
                {{'views.user.Upload file' | translate}}
              </button>
          </div>
      </div>
    
            </div>
    </div>
    </div>
   
   <div style="display: flex;margin-left: 7%;
    margin-top: 19%;">
      <app-terms-conditions></app-terms-conditions>
  </div>