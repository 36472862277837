import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  private _baseHref: string;
  private _token;
  public get baseHref(): string {
    return this._baseHref;
  }
  public set baseHref(value: string) {
    this._baseHref = value;
  }
  public get token() {
    return this._token;
  }
  public set token(value) {
    this._token = value;
  }
  constructor(private commonService: CommonService, private http: HttpClient) {
    this.baseHref = this.commonService.baseHref;
    this.token=this.commonService.token;
  }
  rolesDetails(params,email) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
'Accept': 'application/json'
  });
  const emailparams = new HttpParams().set('email', email);

  // const requestOptions = { headers };
  return this.http.post<any>(this._baseHref + '/dis/rest/userrole/user', params, { params: emailparams, headers: headers  });

  }
  getRoleAssignmentsList(params,email) {
    // rest/userrole/currentorg/fetch
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
  'Accept': 'application/json'
    });
    const requestOptions = { headers };
    const emailparams = new HttpParams().set('email', email);

    return this.http.post<any>(this._baseHref + '/dis/rest/userrole/currentorg/fetch', params, { params: emailparams, headers: headers  });
  }
  getRoleRequestsList(params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers };
    return this.http.post(this._baseHref + '/dis/rest/userrolerequest/currentorg/fetch', params, requestOptions);
  }
  getAllRoleRequests(params) {
    // /dis/rest/userrolerequest/fetch
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers };
    return this.http.post(this._baseHref + '/dis/rest/userrolerequest/fetch', params, requestOptions);
  }
  fetchAllRolesFromIDM() {
    return this.http.get(this.baseHref + '/dis/rest/role/');
  }
  getCurrentOrganization() {
    return this.http.get(this._baseHref + '/dis/rest/currentuser/organization', { observe: 'response' });
  }
  updateRole(request,id)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers };
    return this.http.post(this.baseHref + '/dis/rest/role/' + id,request, requestOptions);
  }
  suspend(request,id)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers };
    return this.http.post(this.baseHref + '/dis/rest/role/suspend/' + id,request, requestOptions);
  }
 
  deleteRole(id)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers };
    return this.http.post(this.baseHref + '/dis/rest/role/delete/' + id, requestOptions);
  }
  getAllRoleAssignmentsList() {
    // rest/userrole/currentorg/fetch
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'    });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/userrole/getAllAdminRoles', {}, requestOptions);
  }
  
  getServiceDetailswithId(id) {
    return this.http.get<any[]>(this.baseHref + '/dis/rest/service/'+id, { observe: 'response' });
  }
  sendDataWithIds(ids): Observable<any> {
    // Convert array of IDs into a comma-separated string
    const params = ids.join(',');
    return this.http.get(`/dis/rest/role?id=${params}`);
  }
  getRoleDetails(id) {
    return this.http.get(this._baseHref + '/dis/rest/role/' + id, { observe: 'response' });
  }
  getAvailableServices() {
    return this.http.get(this._baseHref + '/dis/rest/service', { observe: 'response' });
  }
  getServices() {
    return this.http.get(this._baseHref + '/dis/rest/service', { observe: 'response' });
  }
  checkTechnicalID(value, id?) {
    const params = {
      value,
      id
    };
    // /dis/rest/role/checkTechnicalId
    return this.http.get(this._baseHref + '/dis/rest/role/checkTechnicalId', { params , responseType: 'text' });
    // dis/rest/service/role/available
  }
  checkRoleName(value, id?) {
    const params = {
      value,
      id
    };
    // /dis/rest/role/checkTechnicalId
    return this.http.get(this._baseHref + '/dis/rest/role/checkRoleName', { params , responseType: 'text' });
    // dis/rest/service/role/available
  }
  batchuserUpload(file) {
    const headers = new HttpHeaders({
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    const formParams = new FormData();
    formParams.append('file', file);
    return this.http.post<any>(this._baseHref + '/dis/rest/userrole/batchuploadRoles', formParams, { reportProgress: true, observe: 'events', headers });
  }
  batchAminUpload(file) {
    const headers = new HttpHeaders({
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    const formParams = new FormData();
    formParams.append('file', file);
    return this.http.post<any>(this._baseHref + '/dis/rest/userrole/batchuploadAdminRoles', formParams, { reportProgress: true, observe: 'events', headers });
  }
  saveRole(params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers };
    return this.http.put(this._baseHref + '/dis/rest/role', params, requestOptions);
  }
  getCurrentOrganizationUsers(key) {
    const params = new HttpParams().set('email', key);

    return this.http.get(this._baseHref + '/dis/rest/organizationuser/user/currentorg', {params, observe: 'response' });
  }
  getAllUsers(key) {
    return this.http.get<any>(this._baseHref + '/dis/rest/user/internalusers/'+key+"/", { observe: 'response' });
  }
  getCurrentOrganizationRoles() {
    return this.http.get(this._baseHref + '/dis/rest/role/currentorg', { observe: 'response' });
  }
  fetchAllRoles()
  {
    return this.http.get(this._baseHref + '/dis/rest/role', { observe: 'response' });

  }
  getAllRoles() {
    // /dis/rest/role
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
        });
    const requestOptions = { headers };
    return this.http.get<any>(this._baseHref + '/dis/rest/role', requestOptions);  }
  getAllOrganizations() {
    return this.http.get(this._baseHref + '/dis/rest/organization/all', { observe: 'response' });
  }
  saveRoleAssignment(params) {
    // /dis/rest/userrole
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
   
    return this.http.post(this._baseHref + '/dis/rest/userrole', params, httpOptions);
  }
  
  updateRoleDetails(id, params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers };
    return this.http.post(this._baseHref + '/dis/rest/role/' + id, params, requestOptions);
  }
  getRoleAssignmentsLinked(id,request) {
       const headers = new HttpHeaders({
      'Content-Type': 'application/json'
        });
    const requestOptions = { headers };
    return this.http.put<any>(this._baseHref + '/dis/rest/userrole/role/'+id, request, requestOptions);  
  }
  
  getRoleAssignmentsDetails(id) {
    return this.http.get(this._baseHref + '/dis/rest/userrole/' + id);
  }

  getroleassignmentdetailswithallid(request)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post(this._baseHref + '/dis/rest/userrole/getRoleAssignmentDetails', request, requestOptions);
  }
  
  /**New Code * */
  updateRoleAssignmentDetails(request)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
        });
    const requestOptions = { headers };
    return this.http.post(this._baseHref + '/dis/rest/userrole/updateRoleAssignmentStatus', request, requestOptions);
  }
assigngnAdminRole(userid,request)
{
  const headers = new HttpHeaders({
    'Content-Type': 'application/json'
      });
  const requestOptions = { headers };
  return this.http.post(this._baseHref + '/dis/rest/idm/adminRole/assignAdminRoles/'+userid, request, requestOptions);
}
revokeAdminRole(assignType,request)
{
  const headers = new HttpHeaders({
    'Content-Type': 'application/json'
      });
  const requestOptions = { headers };
  return this.http.post(this._baseHref + '/dis/rest/idm/adminRole/revokeAdminRoles/'+assignType, request, requestOptions);
}
assignorgAdminRole(refType,request)
{
  const headers = new HttpHeaders({
    'Content-Type': 'application/json'
      });
  const requestOptions = { headers };
  return this.http.post(this._baseHref + '/dis/rest/idm/adminRole/assignOrgAdminRoles/'+refType, request, requestOptions);
}
}
