import { AfterViewInit, Component, OnInit , ViewChild } from '@angular/core';
import { Subscription } from 'rxjs-compat';
import { CommonService } from 'src/app/common.service';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PermissionService } from 'src/app/permission.service';
import { debounceTime, filter } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { getMatIconFailedToSanitizeLiteralError } from '@angular/material/icon';
import { HttpClient } from '@angular/common/http';
import { RolesService } from 'src/app/roles-service.service';
import { OrganizationService } from 'src/app/Organization_Management/organization-service.service';
import { parsePhoneNumber, parsePhoneNumberWithError } from 'libphonenumber-js';
@Component({
  selector: 'app-userdetail',
  templateUrl: './userdetail.component.html',
  styleUrls: ['./userdetail.component.css']
})
export class UserdetailComponent implements OnInit ,AfterViewInit {

  user;
  roleData;
  OrgData;
  federatedClient: any;
  isDisabled = true;
  preferedMobileFlag: boolean;
  tenantValue: any;
  requireReRegisterButtonClicked = false;
alerts=[];
  id ;
  private routeSubc: Subscription;
  uniqueValue;
  organizationcount = 0;
  otpapplastchangeddate;
  displayedColumns: string[] = ['status', 'organization'];
  roledisplayedColumns: string[] = ['status', 'role', 'organization', 'action'];
  adminroledisplayedColumns: string[] = ['role','organization'];
  resultsLength = 0;
  roleassignmentcount = 0;
  adminroleassignmentcount=0;
  isLoadingResults = true;
  isRateLimitReached = false;
  dataSource: MatTableDataSource<any>;
  roledataSource: MatTableDataSource<any>;
  adminroledataSource: MatTableDataSource<any>;
adminroleData;
  filter = {};
  mobileFlag = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  filteredAndPagedIssues = new MatTableDataSource<any>([]);
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  @ViewChild('paginatorroles') paginatorroles: MatPaginator;
  @ViewChild('paginatoradminroles') paginatoradminroles: MatPaginator;
  @ViewChild('paginatororgs') paginatororgs: MatPaginator;



existiingphonechangeFlag=false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  userrequestForm: FormGroup = new FormGroup({
    givenName: new FormControl('', [
      Validators.required,
      Validators.pattern(/^(?! )[^\s_]+(?: [^\s_]+)*$/)

    ]),
    sn: new FormControl('', [Validators.required,   
      Validators.pattern(/^(?! )[^\s_]+(?: [^\s_]+)*$/)

    ]),
   mainLanguage: new FormControl('', Validators.required),
    mail: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9À-힣.!#$%&'*/=?^_`{|}~-]+@[a-zA-Z0-9À-힣](?:[a-zA-Z0-9À-힣-]{0,61}[a-zA-Z0-9À-힣])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/), ]),
    emailOtpApprovalDate: new FormControl('' ),
    telephoneNumber: new FormControl({ value: '', disabled: false }),
    preferredMFA : new FormControl(''),
    id: new FormControl(''),
    emailOtp: new FormControl(''),
    federatedClientName: new FormControl({ value: '', disabled: true }),
    federatedClientEmail: new FormControl({ value: '', disabled: true }),
    federatedClientPhone: new FormControl({ value: '', disabled: true }),

  });
  photourl: any;
  tmpkeylastchangeddate: string;
  tmpkeyexpdate: string;
  printLoginCount: any;
  printLastFailedLogin: string;
  printLastSuccessLogin: string;
  resetPasswordButtonClicked: boolean;
  infoalert = false;
  emailOTPDateFlag = false;
  msg: any;
  dangeralert = false;
  dangermsg: any;
  mfaFlag= false;
  infomsg: any;
  registerOtpButtonClicked  = false;
  revokeOtpButtonClicked = false;
  manageCred ={};
  authMethods = {
    authenticatorApp: false,
    mobile: false,
    emailOtp: true
  };
  initialFormValues: any;
  updatedFormFields={};
  preferences: any;
  originalData: any;
  constructor(private organizationService:OrganizationService,private roleService:RolesService,private http: HttpClient, private translate: TranslateService , private commonService: CommonService , private route: ActivatedRoute , private permission: PermissionService , private router: Router) { }
  
  isInValid()
  {
    if (this.user?.userType ==='azureb2b' ||this.user?.userType ==='clientidp') {
      this.userrequestForm.controls['preferredMFA'].setValidators([Validators.required]);
    } else {
      this.userrequestForm.controls['preferredMFA'].clearValidators();
    }
    return  this.userrequestForm.status === 'INVALID';
    // return userForm.$invalid && (userForm.$error && userForm.$error.required && (userForm.$error.required.length !==0 || userForm.$error.required[0].name));
  }
  

navigateorgDetail(row)
   {
    if(this.permission.isAdmin)
    {
      this.router.navigate(['/organization/' + row._id])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
    }
   
   }
   suspendRole(row)
   {
     let request ={
       "userId":"",
       "roleId":"",
       "orgId":"",
       "status":""
       }  
       request.userId=row.userId;
       request.orgId =row.orgId;
       request.roleId =row.roleId;
       request.status = "suspended";
     this.commonService.updateRoleAssignmentStatus(request).subscribe(data =>{
       this.apiTrigger();
 
     })
   }
   activateRole(row)
  {
    let request ={
      "userId":"",
      "roleId":"",
      "orgId":"",
      "status":""
      }
      request.userId=row.userId;
      request.roleId = row.roleId;
      request.orgId =row.orgId;
      request.status = "active";
    this.commonService.updateRoleAssignmentStatus(request).subscribe(data =>{  
      this.apiTrigger();
 
    });
  }
  // true if navigation is successful
  
  deleteRole(row)
  {
    let request ={
      "userId":"",
      "roleId":"",
      "orgId":"",
      "status":""
      }   
    request.userId=row.userId;
    request.roleId = row.roleId;
    request.orgId =row.orgId;
    request.status = "inactive";

       this.commonService.deleteRoleAssignment(request).subscribe(data =>{
      this.apiTrigger();
    });

  }
  ngAfterViewInit() {
   
  }
  filterData(key, value) {
    this.filteredAndPagedIssues.data = this.originalData.filter((item: any) => {
      let rowData;
      if (item[key]!=null || item[key]!="") {
        rowData = item[key].toLowerCase();
        return rowData.includes(value.toLowerCase());
         }
      
    });
  }
apiTrigger() {
  let request={}
  this.filteredAndPagedIssues.paginator = this.paginatorroles;
  if(Object.keys(this.filter).length>0)
   request = { 'page': this.paginatorroles?.pageIndex + 1, 'order':'asc', 'orderBy':'role' , 'filters': this.filter, 'size': this.paginatorroles?.pageSize};
  else
  request = { 'page': this.paginatorroles?.pageIndex + 1, 'order': 'asc', 'orderBy': 'role', 'size': this.paginatorroles?.pageSize};
  
  this.roleService.rolesDetails(request,this.user?.mail).subscribe(data=>{
    if(data.message.roleAssignmentData!=null)
    {
      this.roleassignmentcount = data.message.totalPagedResults;
      if(this.permission.isOrganizationAdmin)
      {
        const orgName = this.commonService.selectedOrg[`name`];
        this.commonService.getAllAvailableOrgs().subscribe(org =>{
          const organizations =org.body;
          if(data.message.roleAssignmentData!=null)
          {
          const filteredRoles = data.message.roleAssignmentData.filter(role => 
            organizations.some(org => org.name === role.orgName)
          
          );
          this.filteredAndPagedIssues.data=filteredRoles;
          this.roleassignmentcount=filteredRoles.length;
          this.originalData= filteredRoles;
        }
        })
      }
      else
      {
      this.filteredAndPagedIssues.data = data.message.roleAssignmentData;
      this.originalData = data.message.roleAssignmentData;
      }
      this.paginatorroles.length=this.roleassignmentcount
    }
    else
    {
      this.resultsLength = 0;
      this.filteredAndPagedIssues.data = [];
      this.paginatorroles.length=0 
    }
  });
  
}
  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.commonService.userDetails(this.id).subscribe(data =>
      {
        this.user = data;
        let roles=[];
        this.filteredAndPagedIssues.paginator = this.paginatorroles;
        this.apiTrigger();
        const currentuser = JSON.parse(localStorage.getItem("currentUserDetails"));
        if(currentuser.mail==this.user.mail)
        {
          if(currentuser?.authzRoles?.length>0)
          {
            currentuser?.authzRoles.forEach(element => {
                let role={};
                role['role']=element._refResourceId;
                role['organizationName']=null;
                role['adminRoleFlag']=true;
                roles.push(role);
              });  
          }
          if(this.user?.adminOfOrgIDs?.length>0)
            {
              this.user?.adminOfOrgIDs.forEach(element => {
                let role={};
                role['role']="adminOfOrg";
                this.organizationService.getOrganizationDetails(element).subscribe(data =>{
                  role['organizationName']=data.name;
                  role['adminRoleFlag']=false;
                  roles.push(role);
                  this.adminroleData = roles;
                  this.adminroleassignmentcount = roles.length;
                  this.adminroledataSource = new MatTableDataSource(this.adminroleData);
                  this.adminroledataSource.paginator = this.paginatoradminroles;
                  this.adminroledataSource.sort = this.sort;
                });
                
              });
              
            }
            else if(roles.length>0)
              {
                this.adminroleData = roles;
                this.adminroleassignmentcount = roles.length;
                this.adminroledataSource = new MatTableDataSource(this.adminroleData);
                this.adminroledataSource.paginator = this.paginatoradminroles;
                this.adminroledataSource.sort = this.sort;
              }
              this.OrgData = currentuser.memberOfOrg;
        }
        else
        {
          if(this.user?.authzRoles?.length>0)
            {
              this.user?.authzRoles.forEach(element => {
                  let role ={};
                  role['role']=element._refResourceId;
                  role['adminRoleFlag']=true;
                  roles.push(role);
                });  
            }
            if(this.user?.adminOfOrgIDs?.length>0)
              {
                this.user?.adminOfOrgIDs.forEach(element => {
                  let role={};
                  role['role']="adminOfOrg";
                  this.organizationService.getOrganizationDetails(element).subscribe(data =>{
                    role['organizationName']=data.name;
                    role['adminRoleFlag']=false;
                    roles.push(role);
                    this.adminroleData = roles;
                this.adminroleassignmentcount = roles.length;
                this.adminroledataSource = new MatTableDataSource(this.adminroleData);
                this.adminroledataSource.paginator = this.paginatoradminroles;
                this.adminroledataSource.sort = this.sort;
                  })
                 
                   
                  
                });
                
              }
              else if(roles.length>0)
              {
                this.adminroleData = roles;
                this.adminroleassignmentcount = roles.length;
                this.adminroledataSource = new MatTableDataSource(this.adminroleData);
                this.adminroledataSource.paginator = this.paginatoradminroles;
                this.adminroledataSource.sort = this.sort;
              }
              this.OrgData = this.user.memberOfOrg;
        }
        

        this.commonService.managecred(this.user.mail).subscribe(credData =>{
          this.manageCred = credData;

          if(this.manageCred!=null && this.manageCred['registerApp'] === true)
          {
            this.authMethods.authenticatorApp = true;
          }
          else
          {     
            this.authMethods.authenticatorApp = false;

          }
        })
        if(this.user.memberOfOrg!=null)
        this.organizationcount = this.user.memberOfOrg.length;
      else
      this.organizationcount =0;
        this.dataSource = new MatTableDataSource(this.OrgData);
        
        this.dataSource.paginator = this.paginatororgs;
        this.dataSource.sort = this.sort;
        
        if(this.user.azureTenant!=null)
          this.tenantValue = this.user.azureTenant;
        else
        this.tenantValue ="global";

        for (let i = 0; i < this.OrgData.length; i++) {
          if (this.OrgData[i].tenantName === "global") {
              this.tenantValue = "global";
              break;
          }
        }
        this.userrequestForm.get('givenName').setValue(this.user.givenName);
        this.userrequestForm.get('id').setValue(this.user?._id);
        this.userrequestForm.get('sn').setValue(this.user.sn);
        this.userrequestForm.get('mail').setValue(this.user.mail);        
        const emailOtp = this.user.emailOtp;
        if(emailOtp!=null && emailOtp =="true")
        {
          this.userrequestForm.get('emailOtp').setValue(true);

          this.authMethods.emailOtp = true;
        }
        else
        {
          this.userrequestForm.get('emailOtp').setValue(false);

          this.authMethods.emailOtp = false;
        }
        this.userrequestForm.get('emailOtp').valueChanges.subscribe(val => {
          if (this.userrequestForm.get('emailOtp').value) {
            this.authMethods.emailOtp = true;

            this.userrequestForm.controls['emailOtpApprovalDate'].setValidators([Validators.required]);
          } else {
            this.authMethods.emailOtp = false;

            this.userrequestForm.controls['emailOtpApprovalDate'].clearValidators();
          }
          this.userrequestForm.controls['emailOtpApprovalDate'].updateValueAndValidity();

        });
        if(this.user.emailOtpApprovalDate !=null && this.user.emailOtpApprovalDate != undefined)
          {
            const da=new Date(this.user.emailOtpApprovalDate);
            this.userrequestForm.get('emailOtpApprovalDate').setValue(da);

          }
          if(this.user?.preferences?.preferredMFA!=null&&(this.user?.preferences?.preferredMFA==='authenticatorapp'||this.user?.preferences?.preferredMFA==='authenticatorApp'))
        this.userrequestForm.get('preferredMFA').setValue("AuthenticatorApp");
        else
        this.userrequestForm.get('preferredMFA').setValue(this.user?.preferences?.preferredMFA);

        this.userrequestForm.get('telephoneNumber').setValue(this.user.telephoneNumber);
        if(this.user.telephoneNumber !=null)
        {
          this.authMethods.mobile=true;
        }
        else
        {
          this.authMethods.mobile = false;
        }
        this.preferences=this.user['preferences'];
        if(this.user['preferences']==null||this.user['preferences'].preferredLanguage =='' ||this.user['preferences'].preferredLanguage === null )
        {
          this.userrequestForm.get('mainLanguage').setValue("EN");
        }
        else
        this.userrequestForm.get('mainLanguage').setValue((this.user['preferences'].preferredLanguage).toUpperCase());
        this.commonService.getFederatedClientData(this.id).subscribe(federatedClient => {
          this.federatedClient = federatedClient.result;
          if(this.federatedClient !=null&&this.federatedClient.length>0)
          {
          this.userrequestForm.get('federatedClientName').setValue(this.federatedClient[0].federatedclientname || '');
          this.userrequestForm.get('federatedClientEmail').setValue(this.federatedClient[0].clientIAMemail || '');
          this.userrequestForm.get('federatedClientPhone').setValue(this.federatedClient[0].clienthelpdeskphone || '');
          }
          this.initialFormValues = this.userrequestForm.value;
          this.onChanges();
          this.checkMFA();
        });

    });
  }
  onChanges(): void {
    this.userrequestForm.valueChanges
    .pipe(debounceTime(200))
    .subscribe(values => {
      // Compare current values to initial values
      for (const field in values) {
        if (values[field]!=null &&values[field] !== this.initialFormValues[field]) {
          this.updatedFormFields[field] = values[field]; // Update stored initial value on change
        }
      }
});  }
  navigateUserDetail(row)
  {

    this.router.navigate(['/user/history/' + row._id ])
    .then(nav => {
       // true if navigation is successful
    }, err => {
       // when there's an error
    });
  }
  checkMFA()
  {
    const userType = this.user?.userType;
    let mobile =null;
    if(this.userrequestForm.controls.telephoneNumber.value === null)
    {
      mobile=null;
      this.authMethods.mobile=false;

    }
    else
    {
     mobile = this.userrequestForm.get('telephoneNumber').value?.internationalNumber!=undefined ? this.userrequestForm.get('telephoneNumber').value.internationalNumber : this.userrequestForm.get('telephoneNumber').value;
     this.authMethods.mobile=true;

    }
   
    //const otplastchanged = this.user.otpappLastchanged;
    const emailOtp= this.userrequestForm.get('emailOtp').value;
    if(this.tenantValue != 'swiss')
    {
        // if((userType === 'internal' || userType === 'clientidp' || userType === 'azureb2b') || !( (mobile !=null) || (otplastchanged != undefined) || (emailOtp!=false)))
          if((userType === 'internal' || userType === 'clientidp' || userType === 'azureb2b') || !( (this.authMethods.mobile)  || (emailOtp) || (this.manageCred[`registerApp`])))

        {
          this.mfaFlag =  false;
        }
        else
        {
          this.mfaFlag = true;
        }
  }
  else
  {
    this.mfaFlag =  false;
  }
  }
  
 
  checkNumber()
  {
    const values= this.userrequestForm.get('telephoneNumber').value;
    const formphoneNumber = values?.internationalNumber;
    
    if (this.userrequestForm.controls.telephoneNumber.value===null)
    {
       this.mobileFlag = false;
       this.authMethods.mobile = false;
       if(this.user?.telephoneNumber!=null)
       {
        this.mobileFlag=true;
        this.existiingphonechangeFlag = true;
      }
       this.checkMFA();

    }
    else if(this.userrequestForm.controls.telephoneNumber.errors != null)
    {
      const phoneNumber = parsePhoneNumberWithError(formphoneNumber);
      if (phoneNumber) {
        let isValid = phoneNumber.isValid();
        if(phoneNumber.isValid())
        {
          this.user.telephoneNumber=this.userrequestForm.controls.telephoneNumber.value.internationalNumber;
          this.authMethods.mobile = true;
          this.existiingphonechangeFlag = false;
          this.checkMFA();
          this.mobileFlag = false;
         }
        else
        {
          this.mobileFlag = true;
          this.authMethods.mobile = false;
          this.existiingphonechangeFlag = false;     
          this.checkMFA();
        }
     // this.userrequestForm.controls.telephoneNumber.clearValidators();

    }
  }
    else{
      this.user.telephoneNumber=this.userrequestForm.controls.telephoneNumber.value.internationalNumber;
      this.authMethods.mobile = true;
      this.existiingphonechangeFlag = false;
      this.checkMFA();
      this.mobileFlag = true;
    }
  }
  applyFilter(event: Event)  {
    const value = (event.target as HTMLInputElement).value;
    this.dataSource.data = this.OrgData.filter((item: any) => {
      let rowData;
      if (item['name']!=null || item['name']!="") {
        rowData = item['name'].toLowerCase();
        return rowData.includes(value.toLowerCase());
         }
      
    });
    // const filterValue = (event.target as HTMLInputElement).value;
    // this.dataSource.filter = filterValue.trim().toLowerCase();
  
  }
  valueChange($event , user , formval)
  {
    this.userrequestForm.get('emailOtp').setValue($event.target.checked);
    this.onChanges();
    if($event.target.checked)
    {
      this.authMethods.emailOtp = true;
    }
    else
    {
      this.authMethods.emailOtp = false;
    }
    this.checkMFA();
  }
  gotoUserListPage()
  {
    if (this.commonService.previoususerlistlocation )
    {
      this.router.navigate([ this.commonService.previoususerlistlocation ])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
    }
    else
    {
      this.router.navigate(['/user/list'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });

    }
  }
  
  onSubmit(userrequestForm)
  {
   
    if (this.userrequestForm.get('telephoneNumber').value != null)
    {
      this.user.telephoneNumber = this.userrequestForm.get('telephoneNumber').value.internationalNumber;
    }
    const changes = [];

    //const currentValues = this.organizationDetailRequest.value;
    // Iterate over fields and check if there are changes
    for (const field in this.updatedFormFields) {
      
      if (this.updatedFormFields[field]!=null&&this.updatedFormFields[field] !== this.initialFormValues[field]) {
       if(field==='telephoneNumber')
       {
        if(field==='telephoneNumber' && this.updatedFormFields[field]!=null)
        {
          this.user.telephoneNumber = this.userrequestForm.get('telephoneNumber').value.internationalNumber;
          this.updatedFormFields[field] = this.user.telephoneNumber;
        }
        else
        {
          this.updatedFormFields[field] = null;

        }

      
        changes.push({
          operation: "replace",
          field: "telephoneNumber",
          value: this.updatedFormFields[field]
        });
      }
      else if(field==='emailOtp')
        {
         if(field==='emailOtp' && this.updatedFormFields[field]!=null)
         {
           this.user.emailOtp = this.userrequestForm.get('emailOtp').value;
           this.updatedFormFields[field] = this.user.emailOtp?"true":"false";
         }
       
         changes.push({
           operation: "replace",
           field: "telephoneNumber",
           value: this.updatedFormFields[field]
         });
       }
     else  if(field==='mainLanguage')
        {
         if(field==='mainLanguage' && this.updatedFormFields[field]!=null)
         {
          let value;
          this.preferences.preferredLanguage=this.updatedFormFields[field];
          if(this.preferences.preferredMFA==null)
           value ={
            "preferredLanguage":this.updatedFormFields[field]
          }
          else
          {
            value ={
              "preferredLanguage":this.updatedFormFields[field],
              "preferredMFA":this.preferences.preferredMFA
            }
          }
         
         changes.push({
           operation: "replace",
           field: "preferences",
           value: value
         });
        }
       }
       else  if(field==='preferredMFA')
        {
          let value;
         if(field==='preferredMFA' && this.updatedFormFields[field]!=null)
         {
          value ={
            "preferredLanguage":this.preferences.preferredLanguage,
            "preferredMFA":this.updatedFormFields[field]
          }
         
         changes.push({
           operation: "replace",
           field: "preferences",
           value: value
         });
        }
       }
      else
      {
        changes.push({
          operation: "replace",
          field: field,
          value: this.updatedFormFields[field]
        });
      }
      }

    }

    if (changes.length > 0) {
      this.commonService.updateUser(this.id,changes).subscribe(data => {
        if(data.code==0)
        this.router.navigate(['user/list']);
      else
      this.alerts.push({ type: 'danger', msg:data.message});

      },err =>{
       
          
            this.alerts.push({ type: 'danger', msg:"Unable to update user" });
          
      }
    );
    } else {
    }
   }
  requireReRegister()
  {
    this.requireReRegisterButtonClicked = true;
    this.commonService.requireReRegister(this.id).subscribe(data =>{
      if(data.value === 'true')
        {
          this.translate.get('views.js.alerts.user.All MFA methods have been removed.').subscribe(res => {
            this.alerts.push({type: 'info', msg: res});
  
          });
        }
        else
        {
          this.translate.get('views.js.alerts.user.User MFA was not deleted.').subscribe(res => {
            this.alerts.push({type: 'danger', msg: res});
  
          });
        }
    }, error => 
      {
        // this.alerts.push({type: 'danger', msg: 'HTTP ' + error.status + ' - ' + error.statusText});
        this.translate.get('views.js.alerts.user.User MFA was not deleted.').subscribe(res => {
          this.alerts.push({type: 'danger', msg: res});

        });
      });
  }
  authorized() {

    return this.permission.isAdmin || this.permission.isOrganizationAdmin || this.permission.isHelpDeskAdmin;
  }
  authorizedall(){
    return  this.permission.isAdmin;
  }
  authorizedRoleAssignments()
  {
    return this.permission.isAdmin || this.permission.isOrganizationAdmin || this.permission.isHelpDeskAdmin;
  }
  authorizedOrganizations()
  {
    return this.permission.isAdmin || this.permission.isOrganizationAdmin || this.permission.isHelpDeskAdmin;
  }
  closeAlert(index) {
    this.alerts.splice(index, 1);
  }

  resetPassword(email)
  {
    if (this.user.accountStatus == "suspended") {
      this.infoalert = false;
      this.dangeralert = true;
      this.translate.get('views.js.alerts.user.ResetSuspendedMsg').subscribe(res => {
      this.alerts.push({type: 'danger', msg: res});
    });
  } 
  else
  {
    this.resetPasswordButtonClicked = true;
    this.commonService.resetPassword(this.user.mail).subscribe(data =>
      {
        if (data!=null)
        {
          this.infoalert = true;
          this.dangeralert = false;
          this.translate.get('views.js.alerts.user.The reset password email has been sent.').subscribe(res => {
          this.infomsg = res;
          this.alerts.push({type: 'info', msg: res});

        });
        }
        if (data.value === 'false')
        {
          this.infoalert = false;
          this.dangeralert = true;
          this.translate.get('views.js.alerts.user.ResetTooEarlyMsg').subscribe(res => {
          this.dangermsg = res;
          this.alerts.push({type: 'danger', msg: res});

        });
        }
      }, err => {
        this.infoalert = false;
        this.dangeralert = true;
        this.dangermsg = err.status;
      }
      );
    }
  }
  registerOtp()
  {
    if (this.user.accountStatus == "suspended") {
      this.infoalert = false;
      this.dangeralert = true;
      this.translate.get('views.js.alerts.user.AuthAppLinkSuspendedMsg').subscribe(res => {
      this.alerts.push({type: 'danger', msg: res});
    });
  }
    else
    {
    this.registerOtpButtonClicked = true;
    this.commonService.registerAuthApp(this.user.mail).subscribe(data => {
    this.infoalert = true;
    this.dangeralert = false;
    this.translate.get('views.js.alerts.user.An email is sent to the user with an authenticator app registration link.').subscribe(res => {
          this.infomsg = res;
          this.alerts.push({type: 'info', msg: res});

    });
    });
  }
  }
  revokeOtp() {
    this.revokeOtpButtonClicked = true;
    this.otpapplastchangeddate = undefined;
    this.commonService.revokeAuthApp(this.user.mail).subscribe(data => {
      if(data.code==200)
      {
      this.translate.get('views.js.alerts.user.OTPRevokedMsg').subscribe(res => {
        this.infomsg = res;
        this.alerts.push({type: 'info', msg: res});
      });
    }
    else
    {
      this.alerts.push({type: 'info', msg: data.message});

    }
    });
}
}
